import validate from "/usr/src/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import account_45global from "/usr/src/app/middleware/account.global.js";
import manifest_45route_45rule from "/usr/src/app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  account_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/usr/src/app/node_modules/@sidebase/nuxt-auth/dist/runtime/middleware/auth.mjs")
}