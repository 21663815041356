import { createGtm } from "@gtm-support/vue-gtm"

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig().public
  if (config.gtm.id) {
    const cookieConsent = useCookie('megeni-cookie')
    nuxtApp.vueApp.use(createGtm({
      id: config.gtm.id,
      enabled: cookieConsent.value === 'accepted',
      debug: config.gtm.id,
      vueRouter: useRouter(),
    }))
  }
})