const usePortal = () => {
  const { $api } = useNuxtApp()
  const portalConfig = useState('portalConfig', () => null)
  const selectedProcedure = useState('selectedProcedure', () => null)
  const abortedNavigation = useState('abortedNavigation', () => false)

  function enableFlash() {
    abortedNavigation.value = true
    setTimeout(() => {
      abortedNavigation.value = false
    }, 5000)
  }

  async function getPortalConfig(slug = '') {
    if (slug) {
      try {
        const data  = await $api(`/api/provider/${slug}/`)
        if (data) portalConfig.value = {
          slug,
          css: data.portal.css,
          loginBg: data.images.find(i => i.type === 'login'),
          logo: data.images.find(i => i.type === 'logo'),
          portalLogo: data.images.find(i => i.type === 'portal_logo'),
          portalFavicon: data.images.find(i => i.type === 'favicon'),
          providerName: data.name,
          metaDescription: data.meta_description,
          terms: data.portal.terms,
          privacy: data.portal.privacy,
          socialLinks: {
            facebook: data.portal.facebook,
            tiktok: data.portal.tiktok,
            instagram: data.portal.instagram,
            twitter: data.portal.twitter,
            youtube: data.portal.youtube,
            linkedin: data.portal.linkedin,
            medium: data.portal.medium,
          },
          data,
        }
      } catch (error) {
        console.log("🚀 ~ getPortalConfig ~ error:", error)
      }
    } else {
      const { data: userData, status } = useAuth()
      if (status.value === 'authenticated') {
        portalConfig.value = userData.value.user.provider?.portal
        ? {
            slug: userData.value.user.provider.slug,
            css: userData.value.user.provider.portal.css,
            loginBg: userData.value.user.provider.images.find(i => i.type === 'login'),
            logo: userData.value.user.provider.images.find(i => i.type === 'logo'),
            portalLogo: userData.value.user.provider.images.find(i => i.type === 'portal_logo'),
            portalFavicon: userData.value.user.provider.images.find(i => i.type === 'favicon'),
            providerId: userData.value.user.provider.id,
            providerName: userData.value.user.provider.name,
            metaDescription: userData.value.user.provider.meta_description,
            terms: userData.value.user.provider.portal.terms,
            privacy: userData.value.user.provider.portal.privacy,
              socialLinks: {
              facebook: userData.value.user.provider.portal.facebook,
              tiktok: userData.value.user.provider.portal.tiktok,
              instagram: userData.value.user.provider.portal.instagram,
              twitter: userData.value.user.provider.portal.twitter,
              youtube: userData.value.user.provider.portal.youtube,
              linkedin: userData.value.user.provider.portal.linkedin,
              medium: userData.value.user.provider.portal.medium,
            }
          }
        : null
      }
    }
  }

  return {
    portalConfig,
    selectedProcedure,
    abortedNavigation,
    enableFlash,
    getPortalConfig,
  }
}

export default usePortal