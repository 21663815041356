const useSettings = () => {
  const { $api } = useNuxtApp()
  const fallbackCurrency = {
    id: 0,
    currency: 'USD',
    currencyName: 'US Dollar',
    symbol: '$',
    usdExchangeRate: 1
  }
  const selectedCurrency = useState('selectedCurrency', () => null)
  const exchangeRates = useState('exchangeRates', () => [])
  const loginFinalised = useState('loginFinalised', () => true)

  async function getExchangeRates() {
    if (exchangeRates.value.length > 0) return

    try {
      const { data } = await useAsyncData('exchangeRates', () => $api('/api/exchangerate/'))
      if (data.value) {
        exchangeRates.value = data.value.results.map(currency => ({
          id: currency.id,
          currency: currency.currency,
          currencyName: currency.currency_name,
          symbol: currency.symbol,
          usdExchangeRate: currency.usd_exchange_rate
        }))
      }
    } catch (error) {
      if (import.meta.dev) {
        console.error('🚀 ~ getExchangeRates ~ error:', error)
      }
      throw createError({
        ...error,
        statusMessage: 'Could not fetch exchange rates.',
      })
    }
  }

  return {
    fallbackCurrency,
    selectedCurrency,
    exchangeRates,
    getExchangeRates,
    loginFinalised,
  }
}

export default useSettings