import { $fetch } from 'ofetch'
import { useRequestHeaders } from "nuxt/app"

export default defineNuxtPlugin(nuxtApp => {
    const runtimeConfig = useRuntimeConfig()
    const { data: authObj } = useAuth()

    const api = $fetch.create({
      baseURL: runtimeConfig.public.ssrUrl,
      onRequest({ request, options }) {
        const headers = useRequestHeaders()
        if (import.meta.server) {
          headers.Accept = 'application/json'
        }
        const authHeaderValue = options?.auth
          ? `${options.auth.key} ${options.auth.token}`
          : authObj.value?.token?.accessToken
            ? `Token ${authObj.value?.token?.accessToken}`
            : ''
        if (authHeaderValue) headers.Authorization = authHeaderValue

        options.headers = {
          ...headers
        }
      },
      async onResponseError({request, response }) {
        if (import.meta.dev) {
          console.log(
              '❗[fetch response error]',
              request,
              response.status,
              response.body
          )
        }
        if (response.status === 401) {
          await nuxtApp.runWithContext(() => navigateTo('/login'))
        }
      },

    })

    return {
      provide: {
        api,
      }
    }
})