export default defineNuxtRouteMiddleware((to, from) => {
  const { data, status  } = useAuth()
  const loggedIn = status.value === 'authenticated'
  let shouldFillAccount = true
  if (loggedIn) {
    shouldFillAccount = !data.value?.user.birthday || !data.value?.user.last_name ||
                        !data.value?.user.first_name || !data.value?.user.first_name ||
                        !data.value?.user.sex || !data.value?.user.height || !data.value?.user.weight
  }

  if (shouldFillAccount) usePortal().enableFlash()
  if (loggedIn && shouldFillAccount && to.path !== '/account' && to.path !== '/login-finalise') {
    return navigateTo('/account')
  }
})
